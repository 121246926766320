import React from 'react'
import styles from './About.module.css'
import aboutintro from '../../Assets/About/about_intro.jpg'
import quality from '../../Assets/About/quality.jpg'
import director from '../../Assets/About/director.jpg'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <div className={styles.about}>
      {/* Hero Section */}
      <section className={styles.hero}>
        <div className={styles.heroContent}>
          <h1>About Majstro Healthcare</h1>
          <p>Born out of compassion for healthcare, with a strong desire and passion to contribute to healthcare in India and globally.</p>
        </div>
      </section>

      {/* Introduction Section */}
      <section className={styles.introduction}>
        <div className={styles.container}>
          <div className={styles.introText}>
            <h2>Who We Are</h2>
            <p>"Majstro Healthcare (Opc) Pvt Ltd" is proud to be a part of the rapidly-growing healthcare industry in India. We are a Pharmaceutical Marketing Company based in Pune, Maharashtra, offering a wide range of products including Critical Care range.</p>
          </div>
          <div className={styles.introImage}>
            <img src={aboutintro} alt="Majstro Healthcare Facility" />
          </div>
        </div>
      </section>

      {/* Our Approach Section */}
      <section className={styles.approach}>
        <div className={styles.container}>
          <h2>Our Approach</h2>
          <div className={styles.approachGrid}>
            <div className={styles.approachItem}>
              <i className={`${styles.icon} ${styles.iconQuality}`}></i>
              <h3>Quality Medicines</h3>
              <p>Maintain and quickly deliver quality medicines to our customers at their doorsteps.</p>
            </div>
            <div className={styles.approachItem}>
              <i className={`${styles.icon} ${styles.iconTechnology}`}></i>
              <h3>Latest Technology</h3>
              <p>Utilize latest technology to meet our customer requirements for medicines.</p>
            </div>
            <div className={styles.approachItem}>
              <i className={`${styles.icon} ${styles.iconDistribution}`}></i>
              <h3>Vital Distribution</h3>
              <p>Play a vital role in the distribution of quality medicines.</p>
            </div>
            <div className={styles.approachItem}>
              <i className={`${styles.icon} ${styles.iconService}`}></i>
              <h3>Value Added Services</h3>
              <p>Provide value-added services to healthcare service providers.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Core Values Section */}
      <section className={styles.coreValues}>
        <div className={styles.container}>
          <h2>Our Core Values</h2>
          <p>Our values define who we are and the kind of company we aim to be. We have an unwavering commitment to the highest ethical standards, placing the safety and care of the patients at the centre of our core values.</p>
          <div className={styles.valuesGrid}>
            <div className={styles.valueItem}>
              <h3>Teamwork</h3>
              <p>We believe in "The more minds to contribute, the better the results". We work together across the hall and across the globe, collaborating actively to tackle toughest challenges and advance towards our collective goals.</p>
            </div>
            <div className={styles.valueItem}>
              <h3>Leadership</h3>
              <p>Leadership is a state of action, empowering decision-makers at every level. We strive to move forward with a purpose to create value for today and legacy for tomorrow.</p>
            </div>
            <div className={styles.valueItem}>
              <h3>Integrity</h3>
              <p>The motto of our operations is "Doing what's right - for patients and employees". We act with an unwavering commitment to ethics, honesty and accountability.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Mission and Vision Section */}
      <section className={styles.missionVision}>
        <div className={styles.container}>
          <div className={styles.missionBox}>
            <h2>Our Mission</h2>
            <p>Our aim is to spread our operations to every corner of the country and make our products more readily available. By bringing in a new way of work and a range of innovative products, we ensure the availability of medicines to cure all kinds of ailments at the right place and time.</p>
          </div>
          <div className={styles.visionBox}>
            <h2>Our Vision</h2>
            <p>Our healthcare products ensure that you battle your ailments to victory! We provide the best facilities to our associates to ensure their personal development in order to deliver nothing less than the best. Our future growth aims at large-scale expansion without compromising on quality parameters.</p>
          </div>
        </div>
      </section>

      {/* Quality Section */}
      <section className={styles.quality}>
        <div className={styles.container}>
          <h2>Quality At Its Best</h2>
          <p>"There are no shortcuts to quality. Quality is neither an accident nor does it happen overnight; it must be planned."</p>
          <div className={styles.qualityGrid}>
            <div className={styles.qualityItem}>
              <h3>GMP Guidelines Ensure:</h3>
              <ul>
                <li>Standard and controlled operating procedures</li>
                <li>Validation of process & systems to ensure consistency, precision</li>
                <li>Standardized process irrespective of manpower</li>
                <li>Trained personnel & systemized documentation</li>
              </ul>
            </div>
            <div className={styles.qualityImage}>
              <img src={quality} alt="Quality Control Lab" />
            </div>
          </div>
          <p className={styles.qualityFooter}>Our Manufacturing Partners follow WHO GMP NORMS.</p>
        </div>
      </section>

      {/* Leadership Section */}
      <section className={styles.leadership}>
        <div className={styles.container}>
          <h2>Our Leadership</h2>
          <div className={styles.leaderCard}>
            <img src={director} alt="Mr. Amol M. Tambekar" />
            <div className={styles.leaderInfo}>
              <h3>Mr. Amol M. Tambekar</h3>
              <p>Director</p>
              <p>Mr. Amol M Tambekar has more than 15 years of experience in the pharmaceutical industry.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className={styles.contact}>
        <div className={styles.container}>
          <h2>Contact Us</h2>
          <p>The company is run by a highly experienced and expert management team and has acquired 29 acres of Industrial Land in Maharashtra. Our objective is to set up an ultra-modern pharmaceuticals manufacturing facility focusing on Oral Solids, Nutraceuticals including Cosmeceuticals.</p>
         <Link to='/Contact'><button className={styles.contactButton}>Get in Touch</button></Link> 
        </div>
      </section>
    </div>
  )
}

export default About
