import React, { useState } from 'react'
import styles from './Products.module.css'
import restodsr from '../../Assets/Products/restodsr.jpg'
import sucranist from '../../Assets/Products/sucranist.jpg'
import trimocid_plus from '../../Assets/Products/trimocid_plus.jpg'
import rjesicplus from '../../Assets/Products/rjesicplus.jpg'
import rextetil200dt from '../../Assets/Products/rextetil200dt.jpg'
import repera15gm from '../../Assets/Products/repera15gm.jpg'
import allershield from '../../Assets/Products/ALLERSHIELD.jpg'
import Dexcurb_SF from '../../Assets/Products/Dexcurb_SF.jpg'
import reducof from '../../Assets/Products/reducof.jpg'
import lyconistfort from '../../Assets/Products/lyconistfort.jpg'
import veniga4g from '../../Assets/Products/veniga4g.jpg'
import osumpro from '../../Assets/Products/osumpro.jpg'
import cobanistala from '../../Assets/Products/cobanistala.jpg'
import osumcald3 from '../../Assets/Products/osumcald3.jpg'
import stopurg from '../../Assets/Products/stopurg.jpg'
import trimocidAP from '../../Assets/Products/trimocidAP.jpg'
import { Link } from 'react-router-dom'


const Products = () => {
  const [selectedProduct, setSelectedProduct] = useState(null)

  const products = [
    { id: 1, name: 'RSTRO-DSR', image: restodsr, visualAid: restodsr },
    { id: 2, name: 'Sucranist-0', image: sucranist, visualAid: sucranist },
    { id: 3, name: 'Trimocid Plus', image: trimocid_plus, visualAid: trimocid_plus },
    { id: 4, name: 'Rjesicplus', image: rjesicplus, visualAid: rjesicplus },
    { id: 5, name: 'Rextetil-200DT', image: rextetil200dt, visualAid: rextetil200dt },
    { id: 6, name: 'Repera 1.5 gm', image: repera15gm, visualAid: repera15gm },
    { id: 7, name: 'Allershield', image: allershield, visualAid: allershield },
    { id: 8, name: 'Dexcurb-SF', image: Dexcurb_SF, visualAid: Dexcurb_SF },
    { id: 9, name: 'Reducof', image: reducof, visualAid: reducof },
    { id: 10, name: 'Lyconistfort', image: lyconistfort, visualAid: lyconistfort },
    { id: 11, name: 'Veniga 4g', image: veniga4g, visualAid: veniga4g },
    { id: 12, name: 'Osumpro', image: osumpro, visualAid: osumpro },
    { id: 13, name: 'Cobanist-ALA', image: cobanistala, visualAid: cobanistala },
    { id: 14, name: 'Osumcal-D3', image: osumcald3, visualAid: osumcald3 },
    { id: 15, name: 'Stopurg', image: stopurg, visualAid: stopurg },
    { id: 16, name: 'Trimocid-AP', image: trimocidAP, visualAid: trimocidAP },
  ]

  const openModal = (product) => {
    setSelectedProduct(product)
  }

  const closeModal = () => {
    setSelectedProduct(null)
  }

  return (
    <div className={styles.products}>
      <div className={styles.hero}>
        <h1>Our Products</h1>
        <p>Discover Majastro's innovative pharmaceutical solutions</p>
      </div>

      <div className={styles.productGrid}>
        {products.map((product) => (
          <div key={product.id} className={styles.productCard} onClick={() => openModal(product)}>
            <div className={styles.productImageWrapper}>
              <img src={product.image} alt={product.name} className={styles.productImage} />
            </div>
            <h3 className={styles.productName}>{product.name}</h3>
          </div>
        ))}
      </div>

      {selectedProduct && (
        <div className={styles.modal} onClick={closeModal}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <img src={selectedProduct.visualAid} alt={`${selectedProduct.name} Visual Aid`} className={styles.visualAid} />
            <h2 className={styles.modalTitle}>{selectedProduct.name}</h2>
            <button className={styles.closeButton} onClick={closeModal}>×</button>
          </div>
        </div>
      )}

      <div className={styles.cta}>
        <h2>Need more information?</h2>
        <p>Our team of experts is here to help you find the right product for your needs.</p>
      <Link to='/Contact'><button className={styles.ctaButton}>Contact Us</button></Link>  
      </div>
    </div>
  )
}

export default Products

