import React, { useState, useEffect } from 'react'
import styles from './Home.module.css'
import homeabout from '../../Assets/Home/home_about2.jpg'
import dna from '../../Assets/Home/dna.png'
import nanotechnology from '../../Assets/Home/nanotechnology.png'
import discovery from '../../Assets/Home/discovery.png'
import bioinformatics from '../../Assets/Home/bioinformatics.png'
import doctor from '../../Assets/Home/doctor.webp'
import { Link } from 'react-router-dom'

const Home = () => {
  const [activeProduct, setActiveProduct] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const products = [
    {
      title: "NeuroClear",
      description: "Advanced cognitive enhancement formula for improved mental clarity and focus.",
      icon: "💊"
    },
    {
      title: "CardioGuard",
      description: "Innovative cardiovascular support supplement promoting heart health and circulation.",
      icon: "❤️"
    },
    {
      title: "ImmunoBoost",
      description: "Powerful immune system enhancer with a proprietary blend of vitamins and antioxidants.",
      icon: "🛡️"
    },
    {
      title: "MetaBalance",
      description: "Cutting-edge metabolic regulator for optimal weight management and energy levels.",
      icon: "⚖️"
    }
  ];

  return (
    <div className={styles.home}>
      {/* Hero Section */}
      <section className={styles.hero}>
        <div className={styles.heroContent}>
          <h1 className={styles.heroTitle}>Advancing Healthcare with Majastro</h1>
          <p className={styles.heroSubtitle}>Innovative pharmaceutical solutions for a healthier tomorrow</p>
          <button className={styles.ctaButton}>Explore Our Products</button>
        </div>
        <div className={styles.heroStats}>
          <div className={styles.statItem}>
            <span className={styles.statNumber}>30+</span>
            <span className={styles.statLabel}>Years of Excellence</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statNumber}>50M+</span>
            <span className={styles.statLabel}>Patients Treated</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statNumber}>100+</span>
            <span className={styles.statLabel}>Innovative Products</span>
          </div>
        </div>
        <div className={styles.floatingElements}>
          <div className={`${styles.floatingIcon} ${styles.icon1}`}>💊</div>
          <div className={`${styles.floatingIcon} ${styles.icon2}`}>🧬</div>
          <div className={`${styles.floatingIcon} ${styles.icon3}`}>🩺</div>
          <div className={`${styles.floatingIcon} ${styles.icon4}`}>🔬</div>
        </div>
      </section>

      {/* Featured Products Section */}
      <section className={styles.featuredProducts}>
        <h2 className={styles.sectionTitle}>Our Innovative Products</h2>
        <div className={styles.productsContainer}>
          <div className={styles.productsList}>
            {products.map((product, index) => (
              <div
                key={index}
                className={`${styles.productItem} ${index === activeProduct ? styles.activeProduct : ''}`}
                onClick={() => setActiveProduct(index)}
              >
                <div className={styles.productIcon}>{product.icon}</div>
                <h3 className={styles.productTitle}>{product.title}</h3>
              </div>
            ))}
          </div>
          <div className={styles.productDetails}>
            <div className={styles.productContent}>
              <h3 className={styles.productDetailTitle}>{products[activeProduct].title}</h3>
              <p className={styles.productDescription}>{products[activeProduct].description}</p>
              <button className={styles.learnMoreButton}>Learn More</button>
            </div>
            <div className={styles.productBg}>
              <div className={styles.productBgIcon}>{products[activeProduct].icon}</div>
            </div>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className={styles.aboutUs}>
        <div className={styles.aboutContent}>
          <h2 className={styles.sectionTitle}>About Majastro</h2>
          <p className={styles.aboutText}>
            Medicine is an essential part of life these days. Majstro is committed to ensure that medicines are accessible to everyone. To enable this, we are associated with the manufacturing companies having technologically advanced manufacturing units and godowns of the highest standard.
            <br />
            <br />
            These companies hold WHO GMP certification, and operate in compliance with the ISO standards. We ensure excellence in all our operations, be it scientific research or operational skills.
          </p>
          <Link to='/About'><button className={styles.aboutButton}>Our Story</button></Link>
        </div>
        <div className={styles.aboutImage}>
          <img src={homeabout} alt="Majastro Research Lab" />
        </div>
      </section>

      {/* Research and Innovation Section */}
      <section className={styles.research}>
        <h2 className={styles.sectionTitle}>Research & Innovation</h2>
        <div className={styles.researchGrid}>
          <div className={styles.researchItem}>
            <img src={dna} alt="Genomics Research" />
            <h3>Genomics</h3>
            <p>Unlocking the power of genetic information to develop personalized treatments.</p>
          </div>
          <div className={styles.researchItem}>
            <img src={nanotechnology} alt="Nanotechnology" />
            <h3>Nanotechnology</h3>
            <p>Leveraging microscopic particles for targeted drug delivery and enhanced efficacy.</p>
          </div>
          <div className={styles.researchItem}>
            <img src={discovery} alt="AI in Drug Discovery" />
            <h3>AI-Driven Discovery</h3>
            <p>Harnessing artificial intelligence to accelerate the drug discovery process.</p>
          </div>
          <div className={styles.researchItem}>
            <img src={bioinformatics} alt="Bioinformatics" />
            <h3>Bioinformatics</h3>
            <p>Integrating computational methods to analyze complex biological data.</p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className={styles.testimonials}>
        <h2 className={styles.sectionTitle}>What Healthcare Professionals Say</h2>
        <div className={styles.testimonialSlider}>
          <div className={styles.testimonialItem}>
            <p>"Majastro's products have consistently delivered outstanding results for my patients. Their commitment to quality and innovation is unparalleled."</p>
            <div className={styles.testimonialAuthor}>
              <img src={doctor} alt="Dr. Emily Chen" />
              <div>
                <h4>Dr. Emily Chen</h4>
                <span>Cardiologist, Heart Institute</span>
              </div>
            </div>
          </div>
          {/* Add more testimonial items as needed */}
        </div>
      </section>

      {/* Call to Action Section */}
      <section className={styles.cta}>
        <h2>Ready to Transform Patient Care?</h2>
        <p>Discover how Majastro's innovative products can elevate your practice.</p>
       <Link to='/Contact'><button className={styles.ctaButton}>Contact Our Team</button></Link> 
      </section>
    </div>
  )
}

export default Home
