import React, { useState, useEffect } from 'react'
import styles from './Header.module.css'
import logo from '../../Assets/logo.png'
import { Link } from 'react-router-dom'

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isMobileView, setIsMobileView] = useState(false)  // Track if it's mobile view

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50)
    }

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768)  // Detect mobile view based on width
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false)  // Close mobile menu when switching to desktop
      }
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize) // Listen for resize event
    handleResize() // Initial check on component mount

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <>
      <header className={`${styles.header} ${isScrolled ? styles.scrolled : ''}`}>
        <div className={styles.navbar}>
          <div className={styles.logoContainer}>
            <img src={logo} alt="Pharma Logo" className={styles.logo} />
          </div>
          <nav className={styles.desktopNav}>
            <ul className={styles.navList}>
              <li><Link to="/" className={styles.navLink}>Home</Link></li>
              <li><Link to="/About" className={styles.navLink}>About Us</Link></li>
              <li><Link to="/Products" className={styles.navLink}>Products</Link></li>
              <li><Link to="/Contact" className={styles.navLink}>Contact</Link></li>
            </ul>
          </nav>
          <button 
            className={`${styles.mobileMenuButton} ${isMobileMenuOpen ? styles.open : ''}`} 
            onClick={toggleMobileMenu}
            aria-label="Toggle mobile menu"
          >
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
          </button>
        </div>
      </header>
      <nav className={`${styles.mobileNav} ${isMobileMenuOpen ? styles.open : ''}`}>
        <ul className={styles.mobileNavList}>
          <li><Link to="/" className={styles.mobileNavLink} onClick={toggleMobileMenu}>Home</Link></li>
          <li><Link to="/About" className={styles.mobileNavLink} onClick={toggleMobileMenu}>About Us</Link></li>
          <li><Link to="/Products" className={styles.mobileNavLink} onClick={toggleMobileMenu}>Products</Link></li>
          <li><Link to="/Contact" className={styles.mobileNavLink} onClick={toggleMobileMenu}>Contact</Link></li>
        </ul>
      </nav>
    </>
  )
}

export default Header
