import React from 'react'
import styles from './Footer.module.css'
import { Link } from 'react-router-dom'
import { FaFacebookF , FaTwitter , FaLinkedin, FaInstagramSquare,  } from 'react-icons/fa'
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerSection}>
          <h3>About Majastro</h3>
          <p>Majastro is a leading pharmaceutical company dedicated to improving lives through innovative healthcare solutions.</p>
        </div>
        <div className={styles.footerSection}>
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/About">About Us</Link></li>
            <li><Link to="/Products">Products</Link></li>
            <li><Link to="/Contact">Contact</Link></li>
            
          </ul>
        </div>
        <div className={styles.footerSection}>
          <h3>Contact Us</h3>
          <p>Daulat nagar F/403 near Sinhgad Road police station Suncity Pune</p>
          <p>Email: info@majastro.com</p>
          <p>Phone: +91 8237033300</p>
        </div>
        <div className={styles.footerSection}>
          <h3>Newsletter</h3>
          <p>Stay updated with our latest news and products</p>
          <form className={styles.newsletterForm}>
            <input type="email" placeholder="Enter your email" required />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.socialIcons}>
          <a href="#" className={styles.socialIcon}>
          <FaFacebookF />
          </a>
          <a href="#" className={styles.socialIcon}>
          <FaTwitter />
          </a>
          <a href="#" className={styles.socialIcon}>
          <FaLinkedin />
          </a>
          <a href="#" className={styles.socialIcon}>
          <FaInstagramSquare />
          </a>
        </div>
        <p>&copy; 2023 Majastro. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer

