import React, { useState } from 'react'
import { FaBuilding, FaCity, FaPhoneAlt , FaEnvelope, FaClock } from 'react-icons/fa'
import styles from './Contact.module.css'

const Contact = () => {
  const [activeAccordion, setActiveAccordion] = useState(null)

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index)
  }

  const faqData = [
    {
      question: "What are your business hours?",
      answer: "Our office is open Monday to Friday, 9:00 AM to 5:00 PM IST."
    },
    {
      question: "How quickly can I expect a response?",
      answer: "We aim to respond to all inquiries within 24 business hours."
    },
    {
      question: "Do you offer international shipping for your products?",
      answer: "Yes, we offer international shipping to select countries. Please contact us for more information."
    },
    {
      question: "Can I schedule a meeting with one of your representatives?",
      answer: "You can use our contact form to request a meeting, and we'll get back to you to schedule it."
    }
  ]

  return (
    <div className={styles.contact}>
      <section className={styles.hero}>
        <h1>Contact Us</h1>
        <p>We're here to help and answer any question you might have</p>
      </section>

      <section className={styles.officeLocations}>
        <div className={styles.container}>
          <h2>Our Offices</h2>
          <div className={styles.officeGrid}>
            <div className={styles.officeCard}>
              <div className={styles.officeIcon}>
                <FaBuilding className={styles.icon} />
              </div>
              <h3>Head Office</h3>
              <p>Daulat nagar F/403 near Sinhgad Road police station Suncity Pune</p>
            </div>
            <div className={styles.officeCard}>
              <div className={styles.officeIcon}>
                <FaCity className={styles.icon} />
              </div>
              <h3>Corporate Office</h3>
              <p>Shivpushp landmark 1st floor office no 23 Suncity road Anandnagar pune</p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.contactForm}>
        <div className={styles.container}>
          <h2>Get in Touch</h2>
          <p>Drop in your details and we will have someone get in touch as soon as possible.</p>
          <form>
            <div className={styles.inputGroup}>
              <input type="text" id="name" required />
              <label htmlFor="name">Your Name</label>
            </div>
            <div className={styles.inputGroup}>
              <input type="email" id="email" required />
              <label htmlFor="email">Your Email</label>
            </div>
            <div className={styles.inputGroup}>
              <input type="tel" id="phone" required />
              <label htmlFor="phone">Your Phone</label>
            </div>
            <div className={styles.inputGroup}>
              <textarea id="message" required></textarea>
              <label htmlFor="message">Your Message</label>
            </div>
            <button type="submit" className={styles.submitButton}>Send Message</button>
          </form>
        </div>
      </section>

      <section className={styles.contactInfo}>
        <div className={styles.container}>
          <h2>Contact Information</h2>
          <div className={styles.infoGrid}>
            <div className={styles.infoCard}>
              <FaPhoneAlt  className={styles.icon} />
              <h3>Phone</h3>
              <p>+91 8237033300</p>
            </div>
            <div className={styles.infoCard}>
              <FaEnvelope className={styles.icon} />
              <h3>Email</h3>
              <p>info@majastro.com</p>
            </div>
            <div className={styles.infoCard}>
              <FaClock className={styles.icon} />
              <h3>Business Hours</h3>
              <p>Mon - Fri: 9:00 AM - 5:00 PM</p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.faq}>
        <div className={styles.container}>
          <h2>Frequently Asked Questions</h2>
          <div className={styles.accordionContainer}>
            {faqData.map((item, index) => (
              <div key={index} className={styles.accordionItem}>
                <button
                  className={`${styles.accordionHeader} ${activeAccordion === index ? styles.active : ''}`}
                  onClick={() => toggleAccordion(index)}
                >
                  {item.question}
                  <span className={styles.accordionIcon}></span>
                </button>
                <div className={`${styles.accordionContent} ${activeAccordion === index ? styles.show : ''}`}>
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={styles.cta}>
        <div className={styles.container}>
          <h2>Ready to Connect?</h2>
          <p>Our team is eager to hear from you and provide the support you need.</p>
          <a href="#contact-form" className={styles.ctaButton}>Reach Out Now</a>
        </div>
      </section>
    </div>
  )
}

export default Contact
