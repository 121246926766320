import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home/Home'
import Header from './Components/Header/Header'
import About from './Pages/About/About'
import Products from './Pages/Products/Products'
import Contact from './Pages/Contact/Contact'
import Footer from './Components/Footer/Footer'
import ScrollToTop from './ScrollToTop'

const App = () => {
  return (
    <>
    <BrowserRouter basename='/'>
    <ScrollToTop/>
    <Header/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/About' element={<About/>} />
      <Route path='/Products' element={<Products/>}/>
      <Route path='/Contact' element={<Contact/>} />
    </Routes>
    <Footer/>
    </BrowserRouter>
    
    </>
  )
}

export default App